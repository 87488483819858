import React from "react";
import SuperDashboard from "./SuperDashboard";
import AdminDashboard from "./AdminDashboard";

export default function Dashboard() {
  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const userRole = userInfo?.userRole;

  return (
    <>
      {userRole === "CompanyAdmin" || userRole === "CompanyUser" ? (
        <AdminDashboard />
      ) : (
        <SuperDashboard />
      )}

      {/* {userRole === "CompanyAdmin" ? <AdminDashboard /> : <SuperDashboard />} */}
    </>
  );
}
