import Form from "react-bootstrap/Form";
import logo from "../assets/image/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { forgotPassApi, validateOtpApi } from "../service/authApi";
import { toast } from "react-toastify";
import SampleSlider from "../widget/Slider/SampleSlider";
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

function Otp() {
  const navigate = useNavigate();
  const location = useLocation();
  const [count, setCount] = useState(40);
  const [isComplete, setIsComplete] = useState(false);
  const [values, setValues] = useState(["", "", "", ""]);
  const [isError, setIsError] = useState(false);
  const [loader, setLoader] = useState(false);
  const inputRefs = useRef([]);

  const userEmail = location?.state?.formdata;
  const isReset = location?.state?.forgotPasswordCheck;

  const handleChange = (index, event) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      const newValues = [...values];
      newValues[index] = newValue;
      setValues(newValues);

      if (newValue.length === 1 && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && values[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData("Text");
    if (/^\d{4}$/.test(pastedData)) {
      const newValues = pastedData.split("");
      setValues(newValues);
      newValues.forEach((_, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index].focus();
        }
      });
    }
  };

  // resent otp
  useEffect(() => {
    let timer;
    if (!isComplete) {
      timer = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount > 0) {
            return prevCount - 1;
          } else {
            clearInterval(timer);
            setIsComplete(true);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isComplete]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleReset = () => {
    setCount(30);
    setIsComplete(false);
    setValues(["", "", "", ""]);
    inputRefs.current[0].focus();
    forgotPassApi({ email: userEmail });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (values.some((value) => value === "")) {
      setIsError(true);
      return false;
    } else {
      setIsError(false);
    }
    setLoader(true);
    try {
      const data = {
        email: userEmail.email || userEmail,
        otp: values.join(""),
      };

      const res = await validateOtpApi(data);

      if (res && res.status) {
        toast.success("OTP is submitted successfully", {
          autoClose: 5000,
        });

        setTimeout(() => {
          if (location.state !== null && isReset !== true) {
            navigate("/ResetPassword", { state: { formdata: userEmail } });
          } else {
            navigate("/CreatePassword", {
              state: { formdata: userEmail },
            });
          }
        }, 1000);
      } else {
        toast.error("Invalid OTP. Please try again.", {
          autoClose: 5000,
        });
      }
    } catch (error) {
      // console.log(error);
      toast.error("An error occurred. Please try again later.", {
        autoClose: 5000,
      });
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  };

  return (
    <>
      <div className="container-fluid bg-white custom-parent">
        <div className="row custom-row">
          <div className="col-lg-6 px-5">
            <SampleSlider />
          </div>
          <div className="col-lg-6">
            <div className="login-form">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <div className="form_text">
                <div className="heading d-flex align-item-center">
                  <h1 className="main-heading">OTP Validation</h1>
                </div>
                <p className="sub-heading mb-4">
                  Please Enter 4 digit verification code sent to your Email.
                </p>
                {isError && (
                  <Alert variant="danger" className="mb-4">
                    All fields are required.
                  </Alert>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="form-field">
                    <Form.Group controlId="otpInput">
                      <div className="d-flex gap-4">
                        {values.map((value, index) => (
                          <Form.Control
                            key={index}
                            type="text"
                            value={value}
                            onChange={(e) => handleChange(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            onPaste={handlePaste}
                            ref={(el) => (inputRefs.current[index] = el)}
                            className={`text-center ${
                              isError && value === "" ? "border-danger" : ""
                            }`}
                            maxLength={1}
                          />
                        ))}
                      </div>
                    </Form.Group>
                  </div>
                  <div className="resend_times text-end mb-4">
                    {/* Resend OTP in  */}
                    <strong>
                      {isComplete ? (
                        <div
                          onClick={handleReset}
                          className="text-danger"
                          role="button"
                        >
                          Resend OTP
                        </div>
                      ) : (
                        formatTime(count)
                      )}
                    </strong>
                  </div>
                  <div className="d-grid gap-2 mb-4">
                    <button
                      type="submit"
                      className="btn btn-success common-btn"
                      size="lg"
                    >
                      Continue
                    </button>
                  </div>
                  {/* <div className="d-flex justify-content-center">
                      <Link to="/Login" className="back_button">
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                      </Link>
                    </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
        {loader ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Otp;
