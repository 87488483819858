import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: null,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  companyId: "",
  address1: "",
  address2: "",
  city: "",
  province: "",
  country: "",
  postalCode: "",
  errors: {},
  imageUrl: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile(state, action) {
      return { ...state, ...action.payload };
    },
    updateField(state, action) {
      const { name, value } = action.payload;
      state[name] = value;
      if (state.errors[name]) {
        delete state.errors[name];
      }
    },
    setErrors(state, action) {
      state.errors = action.payload;
    },
    setSelectedImage(state, action) {
      state.imageUrl = action.payload;
    },
    resetProfile(state) {
      return initialState;
    },
  },
});

export const {
  setProfile,
  updateField,
  setErrors,
  setSelectedImage,
  resetProfile,
} = profileSlice.actions;

export default profileSlice.reducer;
