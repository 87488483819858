import axiosInstance from "../axiosInstance";

// Post data for Create Additonal Revenue
export const additionalRevenue = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/AdditionalRevenue/CreateAdditionalRevenue",
      formdata
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Additional Revenue");
  }
};
// Post data for Update Additonal Revenue
export const updateAdditionalRevenue = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/AdditionalRevenue/UpdateAdditionalRevenue",
      formdata
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Additional Revenue");
  }
};
// Post data for Get Additonal Revenue
export const GetAlladditionalRevenue = async () => {
  try {
    const response = await axiosInstance.get(
      "/AdditionalRevenue/GetAllRevenues"
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get All Additional Revenue");
  }
};

//additional Revenue for Delete API
export const DeleteadditionalRevenue = async (id) => {
  try {
    const response = await axiosInstance.post(
      `/AdditionalRevenue/DeleteAdditionalRevenue?id=${id}`,
      {}
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error deleting Alladditional Revenue:",
      error.response ? error.response.data : error.message
    );
  }
};

//getTotal revenue
export const GetTotalAdditionalRevenue = async (message) => {
  try {
    const response = await axiosInstance.get(
      `/AdditionalRevenue/GetTotalAdditionalRevenue?message=${message}`
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get All Additional Revenue");
  }
};
