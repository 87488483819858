import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <div className="row justify-content-center pageNotFind align-items-center my-3 mx-1 p-5  bg-white rounded">
        <div className="col-md-12 text-center mb-3">
          <h1>Oops!</h1>
        </div>
        <div className="col-md-12 text-center mb-3">
          <h5>404- PAGE NOT FOUND</h5>
        </div>
        <div className="col-md-12 text-center mb-3">
          <p>
            Sorry We could not find what you were looking for.
            <br />
            Please ensure you do not have any types or Contact us for further
            assistance
          </p>
        </div>
        <div className="col-md-12 text-center mb-3">
          <Link to="/Dashboard" className="btn btn-success rounded-4 py-2 px-4">
            {" "}
            Back to Dashboard Page
          </Link>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
