import React from "react";
import Chart from "react-apexcharts";
export default function RadialChart({ value = 0 }) {
  const RadialData = {
    series: [value],
    chart: {
      height: 150,
      type: "radialBar",
    },
    dataLabels: {
      enabled: false,
      offsetY: -50,
      formatter: function (val) {
        return val + "%";
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "50%",
          Color: "red",
        },
      },
    },
    track: {
      background: "#f2f2f2",
      strokeWidth: "67%",
      margin: 0,
      dropShadow: {
        enabled: true,
        top: 0,
        left: 0,
        blur: 4,
        opacity: 0.35,
      },
    },

    labels: [""],
  };
  return (
    <>
      <Chart
        options={RadialData}
        series={RadialData?.series}
        type="radialBar"
        height={RadialData.chart.height}
      />
    </>
  );
}
