import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { baseUrlForFile } from "../helpers";
import {
  updateField,
  // setErrors,
  setProfile,
  // setSelectedImage,
} from "../redux/feature/profileSlice";
import { currentUser } from "../helpers";
import { getProfile, updateProfile } from "../service/userManagement";
import user_placeholder from "../assets/image/user_placeholder.png";

export default function Profile() {
  // const [image, setImage] = useState(null);
  const [preview, setPreview] = useState();
  const [selectedImageForm, setSelectedImageForm] = useState(null);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const {
    userId,
    firstName,
    lastName,
    email,
    phoneNumber,
    companyName,
    companyId,
    address1,
    address2,
    city,
    province,
    country,
    postalCode,
    errors,
    imageUrl,
  } = profile;

  useEffect(() => {
    const userInfo = currentUser();
    if (userInfo?.userId) {
      getProfile(userInfo?.userId).then((data) => {
        if (data?.data) {
          dispatch(setProfile({ ...data.data, userId: userInfo?.userId }));
        }
      });
    }
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateField({ name, value }));
  };

  // const validateForm = () => {
  //   const newErrors = {};
  //   if (!firstName) newErrors.firstName = "Enter your first name";
  //   if (!lastName) newErrors.lastName = "Enter your last name";
  //   if (!email) newErrors.email = "Enter your email";
  //   if (!phoneNumber) newErrors.phoneNumber = "Enter your mobile";
  //   if (!companyName) newErrors.companyName = "Enter your company name";
  //   if (!companyId) newErrors.companyId = "Enter your company id";
  //   if (!address1) newErrors.address1 = "Enter your address 1";
  //   if (!address2) newErrors.address2 = "Enter your address 2";
  //   if (!city) newErrors.city = "Enter your city";
  //   if (!province) newErrors.province = "Enter your province";
  //   if (!country) newErrors.country = "Enter your country";
  //   if (!postalCode) newErrors.postalCode = "Enter your postal code";
  //   if (!imageUrl) newErrors.imageUrl = "Please Choose an image";

  //   dispatch(setErrors(newErrors));
  //   return Object.keys(newErrors).length === 0;
  // };

  // const img = useSelector((store) => store?.profile?.imageUrl);

  useEffect(() => {
    setPreview(baseUrlForFile + "/" + imageUrl);
  }, [imageUrl]);
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();
  //   formData.append("Image", selectedImageForm);
  //   formData.append("userId", userId);
  //   formData.append("firstName", firstName);
  //   formData.append("lastName", lastName);
  //   formData.append("email", email);
  //   formData.append("phoneNumber", phoneNumber);
  //   formData.append("companyName", companyName);
  //   formData.append("companyId", companyId);
  //   formData.append("address1", address1);
  //   formData.append("address2", address2);
  //   formData.append("city", city);
  //   formData.append("province", province);
  //   formData.append("country", country);
  //   formData.append("postalCode", postalCode);

  //   try {
  //     const response = await updateProfile(formData);

  //     if (response) {
  //       toast.success("Profile updated successfully!");
  //       getProfile(currentUser().userId).then((data) => {
  //         if (data?.data) {
  //           dispatch(setProfile(data?.data));
  //         }
  //       });
  //     } else {
  //       toast.error("Profile image and fields are mandatory ");
  //     }
  //   } catch (error) {
  //     toast.error("Failed to update profile. Please try again.");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const isValid = validateForm();
    // if (!isValid) {
    //
    // }

    const formData = new FormData();

    if (selectedImageForm) formData.append("Image", selectedImageForm);
    if (userId) formData.append("userId", userId);
    if (firstName) formData.append("firstName", firstName);
    if (lastName) formData.append("lastName", lastName);
    if (email) formData.append("email", email);
    if (phoneNumber) formData.append("phoneNumber", phoneNumber);
    if (companyName) formData.append("companyName", companyName);
    if (companyId) formData.append("companyId", companyId);
    if (address1) formData.append("address1", address1);
    if (address2) formData.append("address2", address2);
    if (city) formData.append("city", city);
    if (province) formData.append("province", province);
    if (country) formData.append("country", country);
    if (postalCode) formData.append("postalCode", postalCode);

    try {
      const response = await updateProfile(formData);

      if (response) {
        toast.success("Profile updated successfully!");
        getProfile(currentUser().userId).then((data) => {
          if (data?.data) {
            dispatch(setProfile(data?.data));
          }
        });
      } else {
        toast.error("Profile image and fields are mandatory.");
      }
    } catch (error) {
      toast.error("Failed to update profile. Please try again.");
    }
  };

  const handleImageChange = (e) => {
    e.stopPropagation();
    // const objectUrl = e.target.files[0];

    setPreview(URL.createObjectURL(e.target.files[0]));
    const file = e.target.files[0];
    if (file) {
      setSelectedImageForm(file);
    } else {
      console.error("Uploaded file is not an image");
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={2}>
            <div className="profile-card d-flex justify-content-center flex-column p-4">
              <div className="image-container mb-3">
                {preview ? (
                  <img
                    src={preview}
                    alt="Profile"
                    className="rounded-circle w-100 h-100"
                  />
                ) : (
                  <img
                    src={user_placeholder}
                    alt="Default Profile"
                    className="rounded-circle w-100 h-100"
                  />
                )}
              </div>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => document.getElementById("imageInput").click()}
              >
                Change Photo
              </button>
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>
          </Col>
          <Col lg={10}>
            <Row>
              <Col md={12}>
                <h5 className="my-3">Profile settings</h5>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="William"
                    value={firstName}
                    onChange={handleChange}
                    isInvalid={!!errors.firstName}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="William"
                    value={lastName}
                    onChange={handleChange}
                    isInvalid={!!errors.lastName}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="example@example.com"
                    value={email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                    className="mb-0"
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="text"
                    name="phoneNumber"
                    placeholder="+1(251) 420-9612"
                    value={phoneNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.phoneNumber}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyName"
                    placeholder="XYZ"
                    value={companyName}
                    onChange={handleChange}
                    isInvalid={!!errors.companyName}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.companyName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Company Id</Form.Label>
                  <Form.Control
                    type="text"
                    name="companyId"
                    placeholder="XYZ"
                    value={companyId}
                    onChange={handleChange}
                    isInvalid={!!errors.companyId}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.companyId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Address 1</Form.Label>
                  <Form.Control
                    type="text"
                    name="address1"
                    placeholder="Address 1"
                    value={address1}
                    onChange={handleChange}
                    isInvalid={!!errors.address1}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address1}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="Address 2"
                    value={address2}
                    onChange={handleChange}
                    isInvalid={!!errors.address2}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address2}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    value={city}
                    onChange={handleChange}
                    isInvalid={!!errors.city}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="province"
                    placeholder="State"
                    value={province}
                    onChange={handleChange}
                    isInvalid={!!errors.province}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.province}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={country}
                    onChange={handleChange}
                    isInvalid={!!errors.country}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="postalCode"
                    placeholder="Postal Code"
                    value={postalCode}
                    onChange={handleChange}
                    isInvalid={!!errors.postalCode}
                    className="mb-0"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.postalCode}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4} className="mt-2">
                <button type="submit" className="btn btn-success px-5 ">
                  Update Profile
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
