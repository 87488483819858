import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

export default function Layout() {
  const [toggleActive, setActive] = useState(false);
  const toggleHandler = () => {
    setActive(!toggleActive);
  };
  return (
    <div
      className={`${toggleActive ? "toggleactive main mt-4" : "main mt-4"}  `}
    >
      <div className="left-section position-relative">
        <Sidebar toggleHandler={toggleHandler} toggleActive={toggleActive} />
      </div>
      <div className="right-section ">
        <Header toggleHandler={toggleHandler} toggleActive={toggleActive} />
        <div className="content-wrapper ">
          <div className="Wrapper">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
