import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Datatable from "../widget/table/Datatable";
import {
  createCompanyUser,
  deleteCompanyUser,
  GetAllCompanyUSers,
  updateCompanyUser,
  GetCompanyUserById,
  updateStatusofUser,
  GetAllCompanyUsersByAdminId,
} from "../service/userManagement";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import eye from "../assets/image/eye.png";
import eye_of from "../assets/image/eye_off.png";
import ReactLoading from "react-loading";
import { currentUser } from "../helpers";

export default function UserManagement() {
  const userInfo = currentUser();
  const userRole = userInfo?.userRole;
  const adMinIDData = userInfo?.userId;

  // eslint-disable-next-line no-unused-vars
  const { state } = useLocation();
  const navigate = useNavigate();
  const companyNameByUser = state?.companyName;
  const companyNameForAdmin = userInfo?.companyName;
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const initialValues = {
    id: null,
    firstName: "",
    lastName: "",
    mobile: "",
    companyName:
      userRole === "CompanyAdmin" ? companyNameForAdmin : companyNameByUser,
    email: "",
    password: "",
    address1: "",
    address2: "",
    province: "",
    city: "",
    postalcode: "",
    country: "",
    companyAdminId: userRole === "CompanyAdmin" ? adMinIDData : state?.adminID,
  };

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setFormValues(initialValues);
    setErrors({});
  };
  const handleShow = () => setShow(true);
  const [showPassword, setShowPassword] = useState(false);

  const {
    firstName,
    lastName,
    mobile,
    companyName,
    email,
    password,
    address1,
    address2,
    province,
    city,
    postalcode,
    country,
  } = formValues;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!firstName) {
      newErrors.firstName = "Enter your First name";
    }
    if (!lastName) {
      newErrors.lastName = "Enter your Last name";
    }
    // if (!mobile) {
    //   newErrors.mobile = "Enter your phone number";
    // } else if (!/^\d{10}$/.test(mobile)) {
    //   newErrors.mobile = "Phone number must be exactly 10 digits";
    // }
    if (!mobile) {
      newErrors.mobile = "Enter your phone number";
    }
    if (!companyName) {
      newErrors.companyName = "Enter Company Name";
    }
    if (!email) {
      newErrors.email = "Enter your email";
    }
    if (!formValues.id) {
      if (!password) {
        newErrors.password = "Enter your Password";
      } else if (password.length < 8) {
        newErrors.password = "Password must be at least 8 characters";
      } else if (!/\d/.test(password)) {
        newErrors.password = "Password must contain at least one digit";
      } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        newErrors.password =
          "Password must contain at least one special character";
      }
    }
    // if (!address1) {
    //   newErrors.address1 = "Enter your address";
    // }
    // if (!address2) {
    //   newErrors.address2 = "Enter your address2";
    // }
    // if (!province) {
    //   newErrors.province = "Enter your province";
    // }
    // if (!city) {
    //   newErrors.city = "Enter your city";
    // }
    // if (!postalcode) {
    //   newErrors.postalcode = "Enter your postalcode";
    // }
    // if (!country) {
    //   newErrors.country = "Enter your country";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const columns = [
    {
      name: "User Name",
      selector: (row) => row.userName,
      sortable: true,
    },
    {
      name: "Total Jobs",
      selector: (row) => row.jobCount,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <Button
          variant={
            row.status === false
              ? "outline-danger"
              : row.status === true
              ? "outline-success"
              : "outline-info"
          }
          onClick={() => handleStatusChange(row)}
        >
          {row.status ? "Active" : "Inactive"}
        </Button>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="actionBtngroup">
          <Link
            to="/AllJobs"
            className="btn custom-btn px-2 py-1 w-auto"
            state={{ userID: row?.id, userName: row?.userName }}
          >
            <FontAwesomeIcon icon={faEye} className="me-1" /> Job
          </Link>
          <Button variant="" onClick={() => handleEdit(row.id)}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button variant="" onClick={() => handleDelete(row.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();

    // const emailExists = data?.some(
    //   (user) => user.email === email && user.id !== formValues.id
    // );

    // if (emailExists) {
    //   toast.error("This email is already in use.", {
    //     autoClose: 100,
    //   });
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     email: "This email is already in use.",
    //   }));
    //   return;
    // }

    if (isValid) {
      setLoader(true);
      try {
        let response = null;
        const obj = {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          mobile: formValues.mobile,
          companyName: formValues.companyName,
          email: formValues.email,
          // address1: formValues.address1,
          // address2: formValues.address2,
          // province: formValues.province,
          // city: formValues.city,
          // postalcode: formValues.postalcode,
          // country: formValues.country,
          companyAdminId:
            userRole === "CompanyAdmin" ? adMinIDData : state?.adminID,
        };
        if (formValues.password) {
          obj.password = formValues.password;
        }
        if (formValues.address1) obj.address1 = formValues.address1;
        if (formValues.address2) obj.address2 = formValues.address2;
        if (formValues.province) obj.province = formValues.province;
        if (formValues.city) obj.city = formValues.city;
        if (formValues.postalCode) obj.postalCode = formValues.postalCode;
        if (formValues.country) obj.country = formValues.country;
        if (formValues.id) {
          obj.id = formValues.id;
          response = await updateCompanyUser(obj);
          toast.success(
            response?.message || "Company User updated successfully!",
            {
              autoClose: 3000,
            }
          );
        } else {
          response = await createCompanyUser(obj);

          if (!response?.status) {
            toast.error(response?.response?.data?.message);
          } else {
            console.log(response, "res=======================");
            toast.success("Company User added successfully!");
          }
        }

        if (response) {
          setData((prevData) => {
            const dataArray = Array.isArray(prevData) ? prevData : [];
            return [response?.data, ...dataArray];
          });
          await getTableData();
          setFormValues(initialValues);
          handleClose();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      }
    }
  };

  const handleStatusChange = async (row) => {
    let statuscode = !row.status;

    const updatedStatus = await updateStatusofUser(row.id, statuscode);
    if (updatedStatus) {
      setData((prevData) =>
        prevData.map((user) =>
          user.id === row.id ? { ...user, status: statuscode } : user
        )
      );

      if (updatedStatus.data) {
        toast.success("User activated successfully!", { autoClose: 3000 });
      } else {
        toast.error("User deactivated successfully!", { autoClose: 3000 });
      }
    } else {
      toast.error("Failed to update user status.", { autoClose: 3000 });
    }
  };

  const handleEdit = async (id) => {
    try {
      let data = await GetCompanyUserById(id);

      if (data && data.status) {
        setFormValues({
          ...formValues,
          id: data.data?.id ?? id,
          firstName: data.data?.firstName,
          lastName: data.data.lastName,
          mobile: data.data.mobile,
          companyName: data.data.companyName || companyName,
          email: data.data.email,
          password: data.data.password,
          address1: data.data.address1,
          address2: data.data.address2,
          province: data.data.province,
          city: data.data.city,
          postalcode: data.data.postalCode,
          country: data.data.country,
        });
        setShow(true);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Get USer
  useEffect(() => {
    getTableData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTableData = async () => {
    if (userRole === "SuperAdmin") {
      await GetAllCompanyUSer();
    } else if (userRole === "CompanyAdmin") {
      await GetCompanyUSer();
    }
  };

  const GetAllCompanyUSer = async () => {
    const result = await GetAllCompanyUsersByAdminId(state?.adminID);

    let filteredData = result?.data.map((company) => {
      return {
        ...company,
        userName: `${company.firstName} ${company.lastName}`,
        address: `${company.address1}, ${company.address2}`,
      };
    });
    setData(filteredData);
  };

  const GetCompanyUSer = async () => {
    const result = await GetAllCompanyUSers();

    const formattedData = result?.data.map((user) => ({
      ...user,
      userName: `${user.firstName} ${user.lastName}`,
      address: `${user.address1}, ${user.address2}`,
      AdminId: user.companyAdminID,
    }));

    setData(formattedData);
  };

  // Delete
  const handleDelete = async (id) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed) {
        const response = await deleteCompanyUser(id);
        if (response) {
          // await GetCompanyUSer();
          await getTableData();
          Swal.fire(
            "Deleted!",
            "Company users has been deleted successfully!",
            "success"
          );
        } else {
          Swal.fire("Error!", "Failed to delete users.", "error");
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Container fluid className="p-0 my-3  ">
      <div className="d-flex justify-content-between align-items-center ">
        {" "}
        <div>
          {userRole === "CompanyAdmin" ? (
            <>
              <div className="main_title"> User Management</div>
              <Breadcrumb className="CompanyAdmin">
                {/* <Breadcrumb.Item className="CurrentClass">
                  User Management
                </Breadcrumb.Item> */}
              </Breadcrumb>
            </>
          ) : (
            <>
              <div className="main_title"> {companyNameByUser}</div>
              <Breadcrumb className="superAdmin">
                <Breadcrumb.Item onClick={() => navigate("/Dashboard")}>
                  Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item className="CurrentClass">
                  User Management
                </Breadcrumb.Item>
              </Breadcrumb>
            </>
          )}
        </div>
        <div>
          <Button className="btn custom-btn mb-3 border-0" onClick={handleShow}>
            <FontAwesomeIcon icon={faPlus} /> Add User{" "}
          </Button>
        </div>
      </div>
      <Row>
        <Col md={12}>
          <div className="border-0 p-3 rounded card">
            <div className="title-container">
              <p>User List</p>
            </div>

            <Datatable columns={columns} data={data} />
          </div>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            {" "}
            {formValues.id ? "Update User" : "Add User"}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="pb-0">
            <div className="custom_form mb-4 position-relative">
              <h6>User Info</h6>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.firstName ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter first name"
                    />
                  </Form.Group>
                  {errors.firstName && (
                    <Form.Text className=" text-danger">
                      Enter your First name
                    </Form.Text>
                  )}
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label> Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.lastName ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Last name"
                    />
                  </Form.Group>
                  {errors.lastName && (
                    <Form.Text className=" text-danger">
                      Enter your Last name
                    </Form.Text>
                  )}
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      type="text"
                      name="mobile"
                      value={mobile}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.mobile ? "border-danger mb-0" : ""
                      }`}
                      placeholder="+1 (251) 420-9612"
                      onKeyPress={(e) => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.mobile && (
                      <Form.Text className="text-danger">
                        {errors.mobile === "Enter your number"
                          ? "Enter your number"
                          : "Phone number must be exactly 10 digits"}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.companyName ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Company name"
                      onKeyPress={(e) => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    {errors.companyName && (
                      <Form.Text className="text-danger">
                        Enter Company name
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.email ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Your Email"
                    />
                    {errors.email && (
                      <Form.Text className="text-danger">
                        Enter your email
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      Password {formValues.id ? "(optional)" : ""}
                    </Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={handleChange}
                        className={`px-3 pe-5 ${
                          errors.password ? "border-danger mb-0" : ""
                        }`}
                        placeholder="**************"
                        // disabled={formValues.id ? true : false}
                      />
                      <img
                        src={showPassword ? eye : eye_of}
                        alt="Toggle Password Visibility"
                        className="position-absolute right_icon"
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </div>
                    {errors.password && (
                      <Form.Text className="text-danger">
                        {errors.password}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="custom_form mb-4 position-relative ">
              <h6>Address</h6>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Address 1</Form.Label>
                    <Form.Control
                      type="text"
                      name="address1"
                      value={address1}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.address1 ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Address 1"
                    />
                    {errors.address1 && (
                      <Form.Text className="text-danger">
                        Enter your address
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      type="text"
                      name="address2"
                      value={address2}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.address2 ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter Address 2"
                    />
                    {errors.address2 && (
                      <Form.Text className="text-danger">
                        Enter your address2
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      name="province"
                      value={province}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.province ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter State"
                    />
                    {errors.province && (
                      <Form.Text className="text-danger">
                        Enter your province
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      value={city}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.city ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter City"
                    />
                    {errors.city && (
                      <Form.Text className="text-danger">
                        Enter your city
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Postal code</Form.Label>
                    <Form.Control
                      type="text"
                      name="postalcode"
                      value={postalcode}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.postalcode ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter postal code"
                    />
                    {errors.postalcode && (
                      <Form.Text className="text-danger">
                        Enter your postalcode
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      name="country"
                      value={country}
                      onChange={handleChange}
                      className={`px-3 ${
                        errors.country ? "border-danger mb-0" : ""
                      }`}
                      placeholder="Enter country"
                    />
                    {errors.country && (
                      <Form.Text className="text-danger">
                        Enter your country
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              variant="outline-danger"
              className="px-5"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button type="submit" variant="success" className="px-5">
              {formValues.id ? "Update" : "Save"}
            </Button>
          </Modal.Footer>
        </form>
        {loader ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          ""
        )}
      </Modal>
    </Container>
  );
}
