import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import eye from "../assets/image/eye.png";
import eye_of from "../assets/image/eye_off.png";
import { currentUser } from "../helpers";
import { changePassword } from "../service/authApi";

export default function PasswordChange() {
  const [email, setEmail] = useState("");
  const [oldshowPassword, setOldShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newshowPassword, setNewShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmshowPassword, setConfirmShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const userInfo = currentUser();
  const emaildata = userInfo?.email;

  React.useEffect(() => {
    if (emaildata) {
      setEmail(emaildata);
    }
  }, [emaildata]);

  const validateForm = () => {
    if (!oldPassword) {
      toast.error("Please enter old Password");
      return false;
    }
    if (!newPassword) {
      toast.error("Please enter New Password");
      return false;
    }

    if (!confirmPassword) {
      toast.error("Please enter confirm Password");
      return false;
    }

    const passwordCriteria =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!passwordCriteria.test(newPassword)) {
      toast.error(
        "New password must be at least 8 characters long, contain one special character, and at least one digit!"
      );
      return false;
    }

    if (newPassword !== confirmPassword) {
      toast.error("The Password and Confirm Password do not match.");
      return false;
    }

    return true;
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await changePassword(email, oldPassword, newPassword, confirmPassword);
        toast.success("Password changed successfully!");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <div>
      <form onSubmit={handlePasswordChange}>
        <Row>
          <Col md={12}>
            <h5 className="my-3">Change Password</h5>
          </Col>
          <Col md={4} style={{ display: "none" }}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                readOnly
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Old Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={oldshowPassword ? "text" : "password"}
                  placeholder="Enter old Password"
                  className="pe-5 ps-2"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <img
                  src={oldshowPassword ? eye : eye_of}
                  alt="Toggle Password Visibility"
                  className="position-absolute right_icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOldShowPassword(!oldshowPassword)}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={newshowPassword ? "text" : "password"}
                  placeholder="Enter new Password"
                  className="pe-5 ps-2"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <img
                  src={newshowPassword ? eye : eye_of}
                  alt="Toggle Password Visibility"
                  className="position-absolute right_icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => setNewShowPassword(!newshowPassword)}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type={confirmshowPassword ? "text" : "password"}
                  placeholder="Enter confirm Password"
                  className="pe-5 ps-2"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <img
                  src={confirmshowPassword ? eye : eye_of}
                  alt="Toggle Password Visibility"
                  className="position-absolute right_icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => setConfirmShowPassword(!confirmshowPassword)}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-success px-5">
            Update Password
          </button>
        </div>
      </form>
    </div>
  );
}
