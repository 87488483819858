import React, { useState } from "react";
import { Container } from "react-bootstrap";

import Profile from "./Profile";
import PasswordChange from "./PasswordChange";

export default function UserProfile() {
  const [activeTab, setActiveTab] = useState("A");

  return (
    <Container fluid className="p-0 my-3 userProfile">
      <h4 className="my-3">User Profile</h4>
      <div className="bg-white p-4">
        <div className={`mainTab ${activeTab.toLowerCase()}tab`} id="cont">
          <div className="tab_btn_area">
            <button
              className={`Abtn ${activeTab === "A" ? "activeTab" : ""}`}
              onClick={() => setActiveTab("A")}
            >
              Edit Profile
            </button>
            <button
              className={`Bbtn ${activeTab === "B" ? "activeTab" : ""}`}
              onClick={() => setActiveTab("B")}
            >
              Security
            </button>
          </div>

          <div className="container_Tab">
            <div className={`aa ${activeTab === "A" ? "" : "hidden"}`}>
              <Profile />
            </div>
            <div className={`bb ${activeTab === "B" ? "" : "hidden"}`}>
              <PasswordChange />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
