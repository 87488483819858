import Form from "react-bootstrap/Form";
import logo from "../assets/image/logo.png";
import { Link, useNavigate } from "react-router-dom";
import message from "../assets/image/mssg.png";
import { useState } from "react";
import { forgotPassApi } from "../service/authApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SampleSlider from "../widget/Slider/SampleSlider";
import ReactLoading from "react-loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required.");
      return;
    }
    setError("");
    setLoader(true);
    try {
      const forgotPasswordOtp = await forgotPassApi({ email });
      if (forgotPasswordOtp.status) {
        toast.success("Sent OTP successfully!");
        setTimeout(() => {
          navigate("/otp", {
            state: { forgotPasswordCheck: false, formdata: email },
          });
        }, 1000);
      }
    } catch (error) {
      toast.error("Invalid email");
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  };

  return (
    <>
      <div className="container-fluid bg-white custom-parent">
        <div className="row custom-row">
          <div className="col-lg-6">
            <SampleSlider />
          </div>
          <div className="col-lg-6">
            <div className="login-form">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <div className="form_text">
                <div className="heading d-flex align-item-center">
                  <h1 className="main-heading">Forgot Password</h1>
                </div>
                <p className="sub-heading mb-4">Please Enter Your Email Id.</p>
                {error && <div className="alert alert-danger">{error}</div>}
                <form onSubmit={handleSubmit}>
                  <div className="form-field">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <div className="position-relative">
                        <img
                          src={message}
                          alt=""
                          className="left_icon position-absolute"
                        />
                        <Form.Control
                          type="email"
                          placeholder="jonas_kahnwald@gmail.com"
                          className={`px-5 ${error ? "border-danger" : ""}`}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="form-field">
                    <div className="d-grid gap-2 mb-4">
                      <button className="btn btn-success common-btn" size="lg">
                        Send
                      </button>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Link to="/Login" className="back_button">
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {loader ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ForgotPassword;
