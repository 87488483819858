import { createSlice } from "@reduxjs/toolkit";

const jobSlice = createSlice({
  name: "jobSlice",
  initialState: {
    jobUpdateFlag: false,
  },
  reducers: {
    updateJobFlag: (state) => {
      state.jobUpdateFlag = !state.jobUpdateFlag;
    },
  },
});

export const { updateJobFlag } = jobSlice.actions;
export default jobSlice.reducer;
