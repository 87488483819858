import Form from "react-bootstrap/Form";
import logo from "../assets/image/logo.png";
import { Link, useNavigate } from "react-router-dom";
import message from "../assets/image/mssg.png";
import user from "../assets/image/user.png";
import ph from "../assets/image/phone.png";
import companyName from "../assets/image/companyName.png";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import { sendOtpApi } from "../service/authApi";
import { toast } from "react-toastify";
import SampleSlider from "../widget/Slider/SampleSlider";
import ReactLoading from "react-loading";

function Signup() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
  });
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormValues({
        ...formValues,
        [name]: numericValue,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }

    if (error[name]) {
      setError((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    let isValid = true;

    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        newErrors[key] = true;
        isValid = false;
      } else if (key === "email" && !validateEmail(formValues[key])) {
        newErrors.email = true;
        isValid = false;
      } else if (key === "phone" && !validatePhone(formValues[key])) {
        newErrors.phone = true;
        isValid = false;
      }
    });

    setError(newErrors);

    if (!isValid) {
      toast.error("Please fill in all the required fields correctly.");
      return;
    }
    setLoader(true);
    try {
      const resOtp = await sendOtpApi({ email: formValues.email });

      if (resOtp.status) {
        toast.success("OTP sent is successfully!");
        setIsLoading(true);
        setTimeout(() => {
          navigate("/Otp", {
            state: { formdata: formValues, forgotPasswordCheck: true },
          });
        }, 5);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("User Already Exist");
      }
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {" "}
      <div className="container-fluid bg-white custom-parent">
        <div className="row custom-row">
          <div className="col-lg-6 ">
            <SampleSlider />
          </div>
          <div className="col-lg-6">
            <div className="login-form">
              <div className="logo">
                <img src={logo} alt="" className="s" />
              </div>
              <div className="form_text">
                <div className="heading d-flex align-item-center">
                  <h1 className="main-heading">Sign Up</h1>
                </div>
                <p className="sub-heading mb-4">Please create a new account.</p>
                {Object.keys(error).length > 0 && (
                  <Alert variant="danger">
                    Please fill in all the required fields correctly.
                  </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <Row className="custom_row">
                    <Col md={6}>
                      <Form.Group
                        className="mb-4 position-relative"
                        controlId="firstName"
                      >
                        <div className="position-relative">
                          <img
                            src={user}
                            alt=""
                            className="left_icon ms-2 position-absolute"
                          />
                          <Form.Control
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            className={`px-5 ${
                              error.firstName ? "border-danger" : ""
                            }`}
                            value={formValues.firstName}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4 position-relative"
                        controlId="lastName"
                      >
                        <div className="position-relative">
                          <img
                            src={user}
                            alt=""
                            className="left_icon ms-2 position-absolute"
                          />
                          <Form.Control
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            className={`px-5 ${
                              error.lastName ? "border-danger" : ""
                            }`}
                            value={formValues.lastName}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="mb-4 position-relative"
                        controlId="email"
                      >
                        <div className="position-relative">
                          <img
                            src={message}
                            alt=""
                            className="left_icon position-absolute"
                          />
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            className={`px-5 ${
                              error.email ? "border-danger" : ""
                            }`}
                            value={formValues.email}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="mb-4 position-relative"
                        controlId="phone"
                      >
                        <div className="position-relative">
                          <img
                            src={ph}
                            alt=""
                            className="left_icon position-absolute"
                          />
                          <Form.Control
                            type="text"
                            name="phone"
                            placeholder="Phone Number"
                            className={`px-5 ${
                              error.phone ? "border-danger" : ""
                            }`}
                            value={formValues.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="mb-4 position-relative"
                        controlId="companyName"
                      >
                        <div className="position-relative">
                          <img
                            src={companyName}
                            alt=""
                            className="left_icon position-absolute"
                          />
                          <Form.Control
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            className={`px-5 ${
                              error.companyName ? "border-danger" : ""
                            }`}
                            value={formValues.companyName}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="form-field">
                    <div className="d-grid gap-2 mb-4">
                      <button
                        type="submit"
                        className="btn btn-success common-btn"
                        size="lg"
                      >
                        {loading ? (
                          <>
                            Sign up
                            <span class="dot-one"> .</span>
                            <span class="dot-two"> .</span>
                            <span class="dot-three"> .</span>
                          </>
                        ) : (
                          <>Sign up</>
                        )}
                      </button>
                    </div>
                    <div className="signuplink text-center">
                      <Link className="text-decoration-none" to="/">
                        Already have an Account?
                        <span className="mx-2">Sign In</span>
                      </Link>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {loader ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Signup;
