import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Datatable from "../widget/table/Datatable";
import { getAllJobs } from "../service/jobs";

export default function AwardedJobList() {
  const [data, setData] = useState([]);
  console.log(data, "datadate");
  const columns = [
    {
      name: "Job Name",
      selector: (row) => row.jobName,
      sortable: true,
    },
    {
      name: "Date",
      // selector: (row) => row.createdDate,
      selector: (row) => {
        const date = new Date(row.createdDate);
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear();
        // return `${month}/${day}/${year}`;

        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? String(hours).padStart(2, "0") : "12";

        return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
      },
      sortable: true,
    },
    {
      name: "Crews",
      selector: (row) => row.crewRunning,
      sortable: true,
    },
    {
      name: "Commission",
      selector: (row) => `${row.commission}%`,
      sortable: true,
    },
    {
      name: "Finance Fee",
      selector: (row) => `${row.financefee}%`,
      sortable: true,
    },
    {
      name: "Breakeven Cost",
      selector: (row) => `$${row.breakEvenJobCost.toLocaleString()}`,
      sortable: true,
    },
    {
      name: "Bid Cost",
      cell: (row) => `$${row.bidCost.toLocaleString()}`,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          className="awraded_job_status"
          //   variant={
          //     row.status === "Pending"
          //       ? "outline-warning"
          //       : row.status === "Awarded"
          //       ? "outline-success"
          //       : "outline-danger"
          //   }
        >
          {row.status}
        </button>
      ),
    },
  ];
  const fetchJobs = async () => {
    const allJobs = await getAllJobs();
    if (allJobs) {
      const awardedJobs = allJobs?.data.filter(
        (job) => job.status === "Awarded"
      );
      setData(awardedJobs);
    }
  };
  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <Card className="border-0 p-3 rounded">
      <div className="title-container">
        <p>Awarded Jobs List</p>
      </div>
      <Datatable columns={columns} data={data} filterName="jobName" />
    </Card>
  );
}
