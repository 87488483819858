import React from "react";
import Stack from "@mui/material/Stack";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
export default function GaugeChart({
  value,
  fillColor,
  width,
  height,
  innerRadius,
  outerRadius,
}) {
  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 1, md: 3 }}>
      <Gauge
        width={width}
        height={height}
        value={value}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        sx={(theme) => ({
          [`& .${gaugeClasses.valueArc}`]: {
            fill: fillColor,
          },
        })}
      />
    </Stack>
  );
}
