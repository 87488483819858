import axios from "axios";
import { baseUrl } from "./helpers";
const baseURL = baseUrl;
// const baseURL = "https://excelyourrevenue-cont-dev.chetu.com/api";
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("AUTH_ACCESS_TOKEN");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = localStorage.getItem("AUTH_ACCESS_TOKEN");
        const refreshToken = localStorage.getItem("AUTH_REFRESH_TOKEN");
        const response = await axios.post(
          `${baseURL}/Account/RefreshToken?accessToken=${token}`,
          {},
          {
            Authorization: `Bearer ${refreshToken}`,
          }
        );
        const newToken = response.data.access_token;
        localStorage.setItem("AUTH_ACCESS_TOKEN", newToken);
        axiosInstance.defaults.headers["Authorization"] = `Bearer ${newToken}`;
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

// import axiosInstance from './axiosInstance';

// const fetchData = async () => {
//   try {
//     const response = await axiosInstance.get('/endpoint');
//     console.log(response.data);
//   } catch (error) {
//     console.error(error);
//   }
// };
