import axiosInstance from "../axiosInstance";

// Post data for Create Company Admin by super Admin
export const createCompanyAdmin = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/UserManagement/CreateCompanyAdmin",
      formdata
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

// Get data for Get Admin
export const getAllCompanyAdmin = async () => {
  try {
    const response = await axiosInstance.get(
      "/UserManagement/GetAllCompanyAdmin"
    );
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get All CompanyAdmin");
  }
};
export const getCompanyAdminById = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/UserManagement/GetCompanyAdminbyid?id=${id}`
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Get CompanyUser by id");
  }
};
// update admin
export const updateCompanyAdmin = async (formdata) => {
  try {
    const response = await axiosInstance.post(
      "/UserManagement/UpdateCompanyAdmin",
      formdata
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------update Company Admin");
  }
};

//delete Company Admin for Delete API
export const deleteCompanyAdmin = async (id) => {
  try {
    const response = await axiosInstance.post(
      `/UserManagement/DeleteCompanyAdmin?id=${id}`,
      {}
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error deleting Company Admin:",
      error.response ? error.response.data : error.message
    );
  }
};
