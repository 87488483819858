import Form from "react-bootstrap/Form";
import logo from "../assets/image/logo.png";
import { Link, useNavigate } from "react-router-dom";
import message from "../assets/image/mssg.png";
import lock from "../assets/image/lock.png";
import React, { useEffect, useState } from "react";
import eye from "../assets/image/eye.png";
import eye_of from "../assets/image/eye_off.png";
import { Button } from "react-bootstrap";
import { loginApi } from "../service/authApi";
import { isAuthenticated } from "../helpers";
import { toast } from "react-toastify";
import SampleSlider from "../widget/Slider/SampleSlider";
import ReactLoading from "react-loading";

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const isAuth = isAuthenticated();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Email and password are required.");
      return;
    }

    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=-{};:"<>,./?])[A-Za-z\d!@#$%^&*()_+=-{};:"<>,./?]{8,}$/;
    if (!passwordRegex.test(password)) {
      setError(
        "Password must be at least 8 characters, contain one digit, and one special character."
      );
      return;
    }
    const data = {
      email,
      password,
    };

    try {
      const resp = await loginApi(data);

      if (resp?.status) {
        toast.success("Logged in successfully!");
        setIsLoading(true);
        setLoader(true);

        setTimeout(() => {
          window.location.href = "/Dashboard";
        }, 0);
      } else {
        toast.error(resp.message);
      }
    } catch (error) {
      toast.error("Some thing went wrong!");
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  };

  React.useEffect(() => {
    if (!isAuth) {
      navigate("/");
    } else {
      navigate("/Dashboard");
    }
  }, [isAuth, navigate]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      if (email && password) {
        localStorage.setItem("rememberme", JSON.stringify({ email, password }));
      }
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem("rememberme");
    if (storedData) {
      const storedDataParsed = JSON.parse(storedData);
      setEmail(storedDataParsed.email);
      setPassword(storedDataParsed.password);
    }
  }, []);

  return (
    <>
      <div className="container-fluid bg-white custom-parent">
        <div className="row custom-row">
          <div className="col-lg-6 ">
            <SampleSlider />
          </div>
          <div className="col-lg-6">
            <div className="login-form">
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
              <div className="form_text">
                <div className="heading d-flex align-item-center">
                  <span></span>
                  <h1 className="main-heading">Sign in</h1>
                </div>
                <p className="sub-heading mb-4">
                  Please Sign in to continue to your account.
                </p>
                {error && <div className="alert alert-danger">{error}</div>}
                <Form onSubmit={handleSubmit}>
                  <div className="form-field">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <div className="position-relative">
                        <img
                          src={message}
                          alt="Message Icon"
                          className="left_icon  position-absolute"
                        />
                        <Form.Control
                          type="email"
                          placeholder="Username"
                          value={email}
                          className={`px-5 ${
                            error && !email ? "border-danger" : ""
                          }`}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="form-field">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <div className="position-relative">
                        <img
                          src={lock}
                          alt="Lock Icon"
                          className="left_icon position-absolute"
                        />
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="******"
                          className={`px-5 ${
                            error && !password ? "border-danger" : ""
                          }`}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />

                        <img
                          src={showPassword ? eye : eye_of}
                          alt="Toggle Password Visibility"
                          className="position-absolute right_icon"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    </Form.Group>

                    <div className="form-check d-flex align-item-center justify-content-between mb-4">
                      <Form className="d-inline">
                        <Form.Check
                          type="checkbox"
                          className="remember ps-0"
                          id="remember-me"
                          label="Remember me"
                          onChange={(e) => handleCheck(e)}
                        />
                      </Form>
                      <Link to="/ForgotPassword" className="forgetbtn">
                        Forgot Password
                      </Link>
                    </div>
                    <div className="d-grid gap-2 mb-4">
                      <Button
                        className="btn btn-success common-btn"
                        size="lg"
                        type="submit"
                      >
                        {loading ? (
                          <>
                            Sign in
                            <span class="dot-one"> .</span>
                            <span class="dot-two"> .</span>
                            <span class="dot-three"> .</span>
                          </>
                        ) : (
                          <>Sign in</>
                        )}
                      </Button>
                    </div>
                    <div className="signuplink text-center">
                      <Link className="text-decoration-none" to="/signup">
                        Need an account? <span>Create one</span>
                      </Link>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {loader ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Login;
