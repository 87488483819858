import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { GetjobConfiguration } from "../service/jobconfigApi";

const initialValues = {
  name: "",
  description: "",
  customername: "",
  jobscofiguration: "",
  equipment: "",
  parts: "",
  permits: "",
  misc: "",
  laborhigh: "",
  jobhours: "",
  labormid: "",
  overheadhours: "",
  overheadsub: "",
  laborlow: "",
  numberpeoples: "",
  numberpeople: "",
  ratedays: "",
  crewrunning: "",
  rateday: "",
  subcost: "",
  email: "",
  phone: "",
  address: "",
};
export default function CreateJob({ handleClose, show, handleShow }) {
  const [jobConfigs, setJobConfigs] = useState(null);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);

  const {
    name,
    description,
    customername,
    jobscofiguration,
    equipment,
    parts,
    permits,
    misc,
    laborhigh,
    jobhours,
    labormid,
    overheadhours,
    overheadsub,
    laborlow,
    numberpeople,
    numberpeoples,
    ratedays,
    crewrunning,
    rateday,
    subcost,
    email,
    phone,
    address,
  } = formValues;

  const formatValue = (num) => {
    if (!num) return "";
    return `$${parseFloat(num).toFixed(2)}`;
  };

  const handleBlur = (e) => {
    const data = formatValue(e.target.value);
    setFormValues((prev) => ({ ...prev, [e.target.name]: data }));
  };

  const handleFocus = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value.replace(/\$|,/g, ""),
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "rateday") {
      const numericValue = value.replace(/[^0-9.]/g, "");

      setFormValues((prev) => ({ ...prev, [e.target.name]: numericValue }));
      return;
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };
  const getConfiguration = async () => {
    const response = await GetjobConfiguration();
    setJobConfigs(response?.data);
  };

  useEffect(() => {
    getConfiguration();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();
    // handleClose1()
    setFormValues(initialValues);
  };
  const validateForm = () => {
    const newErrors = {};
    if (!name) {
      newErrors.name = "Enter your name";
    }
    if (!description) {
      newErrors.description = "Enter your description";
    }
    if (!customername) {
      newErrors.customername = "Enter your customername";
    }
    if (!jobscofiguration) {
      newErrors.jobscofiguration = "Enter your jobscofiguration";
    }
    if (!equipment) {
      newErrors.equipment = "Enter your equipment";
    }
    if (!parts) {
      newErrors.parts = "Enter your parts";
    }
    if (!permits) {
      newErrors.permits = "Enter your permits";
    }
    if (!misc) {
      newErrors.misc = "Enter your misc";
    }
    if (!laborhigh) {
      newErrors.laborhigh = "Enter your laborhigh";
    }
    if (!jobhours) {
      newErrors.jobhours = "Enter your jobhours";
    }
    if (!labormid) {
      newErrors.labormid = "Enter your labormid";
    }
    if (!overheadhours) {
      newErrors.overheadhours = "Enter your overheadhours";
    }
    if (!numberpeople) {
      newErrors.numberpeople = "Enter your numberpeople";
    }

    if (!overheadsub) {
      newErrors.overheadsub = "Enter your overheadsub";
    }
    if (!laborlow) {
      newErrors.laborlow = "Enter your labor low";
    }
    if (!numberpeople) {
      newErrors.numberpeople = "Enter your numberpeople";
    }
    if (!numberpeoples) {
      newErrors.numberpeoples = "Enter your numberpeople";
    }
    if (!ratedays) {
      newErrors.ratedays = "Enter your ratedays";
    }

    if (!crewrunning) {
      newErrors.crewrunning = "Enter your crewrunning";
    }
    if (!rateday) {
      newErrors.rateday = "Enter your rate day";
    }
    if (!subcost) {
      newErrors.subcost = "Enter your sub cost";
    }
    if (!email) {
      newErrors.email = "Enter your email";
    }
    if (!phone) {
      newErrors.phone = "Enter your phone";
    }
    if (!address) {
      newErrors.address = "Enter your address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const [value1, setValue1] = useState(20);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [tooltipValue, setTooltipValue] = useState(0);

  const handleChange1 = (e) => {
    const newValue = e.target.value;
    setValue1(newValue);
    setTooltipValue(tooltipValue);
  };
  const progress3 = (value1 / 100) * 100;

  // progress bar

  const [value2, setValue2] = useState(40);
  const [tooltipVisible2, setTooltipVisible2] = useState(true);
  const [tooltipValue2, setTooltipValue2] = useState(0);

  const handleChange2 = (e) => {
    const newValue2 = e.target.value;
    setValue2(newValue2);
    setTooltipValue2(tooltipValue2);
  };

  const progress2 = (value2 / 100) * 100;

  return (
    <Container fluid className="p-0 my-3  createJobSection">
      <Modal
        centered
        show={show}
        size="lg"
        onHide={handleClose}
        className="createJobSection jobConfigModal"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add Jobs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Breadcrumb>
          <Breadcrumb.Item href="/Alljobs">Jobs</Breadcrumb.Item>
          <Breadcrumb.Item href="/Createjob">Add Job</Breadcrumb.Item>
        </Breadcrumb> */}
            {/* <h4 className="">Add Job’s</h4> */}
            {/* General info */}
            <Row className="mb-3">
              <Col md={12}>
                <h5 className="mb-3">General info</h5>
                <Card className="border-0 p-3 rounded">
                  <Row>
                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Jobs Configuration</Form.Label>

                        <select
                          name="jobscofiguration"
                          value={jobscofiguration}
                          onChange={handleChange}
                          className={`px-3 d-block custom-select ${
                            errors.jobscofiguration ? "border-danger mb-0 " : ""
                          }`}
                        >
                          {" "}
                          {jobConfigs?.map((job) => (
                            <option key={job.id}>{job.jobConfigName}</option>
                          ))}
                        </select>
                        {errors.jobscofiguration && (
                          <Form.Text className="text-danger">
                            Enter your jobscofiguration
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Customer Name</Form.Label>
                        <select
                          name="customername"
                          value={customername}
                          onChange={handleChange}
                          className={`px-3 d-block custom-select ${
                            errors.customername ? "border-danger mb-0 " : ""
                          }`}
                        >
                          <option>select Customer</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        {errors.customername && (
                          <Form.Text className="text-danger">
                            Enter your customername
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.email ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Email"
                        />
                        {errors.email && (
                          <Form.Text className="text-danger">
                            Enter your email
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="number"
                          name="phone"
                          value={phone}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.phone ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Phone"
                        />
                        {errors.email && (
                          <Form.Text className="text-danger">
                            Enter your phone
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          value={address}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.address ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Address"
                        />
                        {errors.address && (
                          <Form.Text className="text-danger">
                            Enter your address
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Enter Job Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.name ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Name"
                        />
                        {errors.name && (
                          <Form.Text className="text-danger">
                            Enter your name
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          name="description"
                          value={description}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.description ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Description"
                        />
                        {errors.description && (
                          <Form.Text className="text-danger">
                            Enter your description
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h5 className="mb-3  ">Crews </h5>
                <Card className="border-0 p-3 rounded">
                  <Row>
                    <Col md={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Crews and Fees</Form.Label>
                        <Form.Control
                          type="number"
                          name="crewrunning"
                          value={crewrunning}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.crewrunning ? "border-danger mb-0" : ""
                          }`}
                          placeholder="132141"
                        />
                        {errors.crewrunning && (
                          <Form.Text className="text-danger">
                            Enter your number crew running
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={4}>
                <h5 className="mb-3">Commission</h5>
                <Card className="border-0 p-3 rounded">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Commission Fee</Form.Label>
                    <div className="range-input-container mt-4">
                      <div className="slider-container">
                        <input
                          type="range"
                          id="range"
                          min="0"
                          max="100"
                          value={value1}
                          onChange={handleChange1}
                          onMouseEnter={() => setTooltipVisible(true)} // Show tooltip on hover
                          onMouseLeave={() => setTooltipVisible(true)} // Hide tooltip when not hovering
                          style={{
                            background: `linear-gradient(to right, #25D366 ${progress3}%, #ccc ${progress3}%)`,
                          }}
                        />
                        {tooltipVisible && (
                          <div>
                            <div
                              className="tooltip"
                              style={{
                                left: `${progress3}%`,
                                transform: "translateX(-50%)",
                              }}
                            >
                              {value1}%
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="range-labels d-flex justify-content-between">
                        <span>0</span>
                        <span>100</span>
                      </div>
                    </div>
                  </Form.Group>
                </Card>
              </Col>{" "}
              <Col md={4}>
                <h5 className="mb-3">Finance</h5>
                <Card className="border-0 p-3 rounded">
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Finance Fee</Form.Label>
                    <div className="range-input-container mt-4">
                      <div className="slider-container">
                        <input
                          type="range"
                          id="range"
                          min="0"
                          max="100"
                          value={value2}
                          onChange={handleChange2}
                          onMouseEnter={() => setTooltipVisible2(true)} 
                          onMouseLeave={() => setTooltipVisible2(true)} 
                          style={{
                            background: `linear-gradient(to right, #25D366 ${progress2}%, #ccc ${progress2}%)`,
                          }}
                        />
                        {/* <div className="value text-center">{value2}%</div> */}
                        {tooltipVisible2 && ( // Show tooltip while hovering
                          <div>
                            <div
                              className="tooltip-item"
                              style={{
                                left: `${progress2}%`,
                                transform: "translateX(-50%)",
                              }}
                            >
                              {value2}%
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="range-labels d-flex justify-content-between">
                        <span>0</span>
                        <span>100</span>
                      </div>
                    </div>
                  </Form.Group>
                </Card>
              </Col>
            </Row>

            {/* Employee Cost and Sub Contractor Cost */}
            <Row className="mb-3 mt-3">
              <Col md={6}>
                <h5 className="mb-3">Employee Cost</h5>
                <Card className="border-0 p-3 rounded">
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Labor High</Form.Label>
                        <Form.Control
                          type="number"
                          name="laborhigh"
                          value={laborhigh}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.laborhigh ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Employees needed"
                        />
                        {errors.laborhigh && (
                          <Form.Text className="text-danger">
                            Enter your labor high
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="number"
                          name="jobhours"
                          value={jobhours}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.jobhours ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Job Hours"
                        />
                        {errors.jobhours && (
                          <Form.Text className="text-danger">
                            Enter your job hours
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Labor Mid </Form.Label>
                        <Form.Control
                          type="number"
                          name="labormid"
                          value={labormid}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.labormid ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Enter Average Monthly Hours per month"
                        />
                        {errors.labormid && (
                          <Form.Text className="text-danger">
                            Enter your labor mid
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="number"
                          name="numberpeople"
                          value={numberpeople}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.numberpeople ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Enter Number of people"
                        />
                        {errors.numberpeople && (
                          <Form.Text className="text-danger">
                            Enter your number people
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Labor Low</Form.Label>
                        <Form.Control
                          type="number"
                          name="laborlow"
                          value={laborlow}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.laborlow ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Enter Average Monthly Hours per month"
                        />
                        {errors.laborlow && (
                          <Form.Text className="text-danger">
                            Enter your number laborlow
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3 mt-2"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label></Form.Label>
                        <Form.Control
                          type="number"
                          name="numberpeoples"
                          value={numberpeoples}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.numberpeoples ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Enter Number of people"
                        />
                        {errors.numberpeoples && (
                          <Form.Text className="text-danger">
                            Enter your number number peoples
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Overhead/Hours</Form.Label>
                        <Form.Control
                          type="number"
                          name="overheadhours"
                          value={overheadhours}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.overheadhours ? "border-danger mb-0" : ""
                          }`}
                          placeholder="0"
                        />
                        {errors.overheadhours && (
                          <Form.Text className="text-danger">
                            Enter your number overhead hours
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={12}>
                    <h5 className="mb-3 text-white">.</h5>
                    <Card className="border-0 p-3 rounded">
                      <Row>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Overhead/Sub Contractor</Form.Label>
                            <Form.Control
                              type="number"
                              name="overheadsub"
                              value={overheadsub}
                              onChange={handleChange}
                              className={`px-3 ${
                                errors.overheadsub ? "border-danger mb-0" : ""
                              }`}
                              placeholder="Days needed"
                            />
                            {errors.overheadsub && (
                              <Form.Text className="text-danger">
                                Enter your number overhead sub
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label></Form.Label>
                            <Form.Control
                              type="text"
                              name="ratedays"
                              value={formValues.ratedays}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              className={`px-3 ${
                                errors.ratedays ? "border-danger mb-0" : ""
                              }`}
                              placeholder="Rate/day"
                            />
                            {errors.ratedays && (
                              <Form.Text className="text-danger">
                                Enter your number rate days
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Sub Contractor Cost</Form.Label>
                            <Form.Control
                              type="number"
                              name="subcost"
                              value={subcost}
                              onChange={handleChange}
                              className={`px-3 ${
                                errors.subcost ? "border-danger mb-0" : ""
                              }`}
                              placeholder="Days needed"
                            />
                            {errors.subcost && (
                              <Form.Text className="text-danger">
                                Enter your number sub cost
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label></Form.Label>

                            <Form.Control
                              type="text"
                              name="rateday"
                              value={formValues.rateday}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              className={`px-3 ${
                                errors.rateday ? "border-danger mb-0" : ""
                              }`}
                              placeholder="Rate/day"
                            />
                            {errors.rateday && (
                              <Form.Text className="text-danger">
                                Enter your number rate day
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>{" "}
            </Row>
            {/* Supply Cost */}
            <Row className="mb-3">
              <Col md={12}>
                <h5 className="mb-3">Supply Cost</h5>
                <Card className="border-0 p-3 rounded">
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="productName"
                          value={formValues.productName}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.productName ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Product Name"
                        />
                        {errors.productName && (
                          <Form.Text className="text-danger">
                            Enter Product Name
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Product Cost</Form.Label>
                        <Form.Control
                          type="text"
                          name="productName"
                          value={formValues.productCost}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.productCost ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Product Cost"
                        />
                        {errors.productCost && (
                          <Form.Text className="text-danger">
                            Enter Product Cost
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Equipment</Form.Label>
                        <Form.Control
                          type="text"
                          name="equipment"
                          value={formValues.equipment}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.equipment ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Equipment"
                        />
                        {errors.equipment && (
                          <Form.Text className="text-danger">
                            Enter your equipment
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Parts</Form.Label>
                        <Form.Control
                          type="text"
                          name="parts"
                          value={formValues.parts}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.parts ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Parts"
                        />
                        {errors.parts && (
                          <Form.Text className="text-danger">
                            Enter your parts
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Permits</Form.Label>
                        <Form.Control
                          type="text"
                          name="permits"
                          value={formValues.permits}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.permits ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Permits"
                        />
                        {errors.permits && (
                          <Form.Text className="text-danger">
                            Enter your permits
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Misc</Form.Label>
                        <Form.Control
                          type="text"
                          name="misc"
                          value={formValues.misc}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className={`px-3 ${
                            errors.misc ? "border-danger mb-0" : ""
                          }`}
                          placeholder="Misc"
                        />
                        {errors.misc && (
                          <Form.Text className="text-danger">
                            Enter your misc
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="success" type="submit" onClick={handleClose}>
              Submit Job
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Container>
  );
}
