import React from "react";
import Chart from "react-apexcharts";

export default function DonutChart({ series, labels, colors,height }) {
  const options = {
    chart: {
      type: "donut",
    },
    labels: labels,
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: "bottom",
      offsetY: 0,
    },
  };

  return <Chart options={options} series={series} type="donut" height={height} />;
}
