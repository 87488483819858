import { configureStore } from "@reduxjs/toolkit";
import jobSlice from "../feature/jobSlice";
import profileReducer from "../feature/profileSlice";

const store = configureStore({
  reducer: {
    jobSlice,
    profile: profileReducer,
  },
});

export default store;
// import { configureStore } from "@reduxjs/toolkit";
// import jobSlice from "../feature/jobSlice";
// import profileReducer from "../feature/profileSlice";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// const persistConfig = {
//   key: "root",
//   storage,
// };

// const rootReducer = {
//   jobSlice,
//   profile: profileReducer,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
// });

// const persistor = persistStore(store);

// export { store, persistor };
